import React from 'react';

function RedirectionProjectManager({ redirectBack, initialQuotationRequest, handleSubmit }) {
    return (
        <>
            <p>Voor projectleider verwachten we geen doorverwijzing kansactie</p>
        </>
    );
}

export default RedirectionProjectManager;
