import React from 'react';

function RedirectionCoach({ redirectBack, initialQuotationRequest, handleSubmit }) {
    return (
        <>
            <p>Voor coach verwachten we geen doorverwijzing kansactie</p>
        </>
    );
}

export default RedirectionCoach;
