import React from 'react';

function VisitProjectManager({ redirectBack, initialQuotationRequest, handleSubmit }) {
    return (
        <>
            <p>Voor projectleider verwachten we geen bezoek kansactie</p>
        </>
    );
}

export default VisitProjectManager;
