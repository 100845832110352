import React from 'react';

function RedirectionOccupant({ redirectBack, initialQuotationRequest, handleSubmit }) {
    return (
        <>
            <p>Voor bewoner verwachten we geen doorverwijzing kansactie</p>
        </>
    );
}

export default RedirectionOccupant;
